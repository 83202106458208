import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Layout, SEO } from '@components';
import {
  Greeting,
  FindOut,
  OtherRefundClaims,
} from '@home-page';

function Index({ location }) {
  const [ctaLinks, setCtaLinks] = useState({
    WFH_LINK: '',
    UNIFORM_LINK: '',
  });

  useEffect(() => {
    setCtaLinks({
      WFH_LINK: `${process.env.WFH_LP_LINK}/${location.search}`,
      UNIFORM_LINK: `${process.env.UNIFORM_LP_LINK}/${location.search}`,
    });
  }, []);

  return (
    <Layout location={location}>
      <SEO />
      <Greeting ctaLinks={ctaLinks} />
      <FindOut ctaLinks={ctaLinks} />
      <OtherRefundClaims ctaLinks={ctaLinks} />
    </Layout>
  );
}

Index.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  ctaLinks: PropTypes.shape({
    WFH_LINK: PropTypes.string.isRequired,
    UNIFORM_LINK: PropTypes.string.isRequired,
  }).isRequired,
};

export default Index;
